import React, { useLayoutEffect } from 'react'
import { OhpeneerTextColMobile } from '@/components/GridViewOhpeneers/styled'

const OhpeneerTextBlockMobile: React.FC<{
  ohpeneer
  onClickHandler: (val) => void
}> = ({ ohpeneer, onClickHandler }) => {
  useLayoutEffect(() => {
    const newToScroll = document.querySelector('#scrollBlock')

    if (newToScroll) {
      newToScroll.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      })
    }
  })
  return (
    <OhpeneerTextColMobile
      onClick={() => onClickHandler(ohpeneer.id)}
      id="scrollBlock"
      col={1}
    >
      <div className="contentHeight" />
      <div className="content">
        <h3>{ohpeneer.name}</h3>
        <p>{ohpeneer.quote.jobTitle}</p>
      </div>
    </OhpeneerTextColMobile>
  )
}

export default OhpeneerTextBlockMobile
