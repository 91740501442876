import React, { useState } from 'react'
import { graphql, navigate } from 'gatsby'
import Page from '@/components/Page'
import PageWrapper from '@/components/PageWrapper'
import { pageFadeInOut } from '@/modules/animationVariants'
import {
  OhpeneerMobilePageWrap,
  OhpeneerMobileRow,
  OhpeneerMobileCol,
} from '@/components/GridViewOhpeneers/styled'
import OhpeneerTextBlockMobile from '@/components/GridViewOhpeneers/OhpeneerTextBlockMobile'

export const query = graphql`
  query {
    allCmsOhpeneer(filter: { status: { eq: "published" } }) {
      edges {
        node {
          id
          slug
          status
          created_on
          name
          function
          image {
            data {
              url
            }
          }
          quote
          featured
          overview
          changemaker_image {
            data {
              url
            }
          }
        }
      }
    }
  }
`

const transformNode = ({ node }) => ({
  inOverview: node.overview,
  inChangemakers: node.featured,
  id: node.slug,
  text: node.text,
  name: node.name,
  source: node?.image?.data?.url,
  quote: {
    text: node.quote,
    jobTitle: node.function,
  },
})

const AllOhpeneersPage: React.FC = ({ data }) => {
  const ohpeneerList = data.allCmsOhpeneer.edges
    .map(transformNode)
    .filter((node) => node.source)
  const [activeId, setActiveId] = useState(null)

  const divideOhpeneerList = (array, chunkSize) =>
    Array(Math.ceil(array.length / chunkSize))
      .fill(0)
      .map((_, index) => index * chunkSize)
      .map((begin) => array.slice(begin, begin + chunkSize))

  const ohpeneerListDivided = divideOhpeneerList(ohpeneerList, 2)

  const onClickOhpeneer = (ohpeneerId) => {
    navigate(`/our-dna/ohpeneer/${ohpeneerId}`)
  }

  return (
    <>
      <Page
        content={
          <PageWrapper>
            <OhpeneerMobilePageWrap>
              {ohpeneerListDivided
                .map((ohpeneer) => {
                  const ohpeneerLeft = ohpeneer[0]
                  const ohpeneerRight = ohpeneer[1]

                  if (!ohpeneerLeft || !ohpeneerRight) {
                    return null
                  }

                  return (
                    <OhpeneerMobileRow key={ohpeneer.id} row>
                      <OhpeneerMobileCol
                        active={activeId === ohpeneer[0]?.id}
                        onClick={() => setActiveId(ohpeneer[0]?.id)}
                        col={0.5}
                      >
                        <div className="wrapper">
                          <div className="size" />
                          <img
                            src={`${ohpeneer[0]?.source}`}
                            alt={ohpeneer[0]?.id}
                          />
                        </div>
                      </OhpeneerMobileCol>
                      <OhpeneerMobileCol
                        active={activeId === ohpeneer[1]?.id}
                        onClick={() => setActiveId(ohpeneer[1]?.id)}
                        col={0.5}
                      >
                        <div className="wrapper">
                          <div className="size" />
                          <img
                            src={`${ohpeneer[1]?.source}`}
                            alt={ohpeneer[1]?.id}
                          />
                        </div>
                      </OhpeneerMobileCol>

                      {(activeId === ohpeneer[0]?.id ||
                        activeId === ohpeneer[1]?.id) && (
                        <OhpeneerTextBlockMobile
                          onClickHandler={onClickOhpeneer}
                          ohpeneer={
                            activeId === ohpeneer[0]?.id
                              ? ohpeneer[0]
                              : ohpeneer[1]
                          }
                        />
                      )}
                    </OhpeneerMobileRow>
                  )
                })
                .filter(Boolean)}
            </OhpeneerMobilePageWrap>
          </PageWrapper>
        }
        settings={{
          backgroundColor: '#113f4f',
          contentAnimationVariants: pageFadeInOut(),
          useCircularWipe: false,
          isSubPage: true,
        }}
      />
    </>
  )
}

export default AllOhpeneersPage
