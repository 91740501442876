import styled, { Box, css } from '@xstyled/styled-components'
import { breakpoints } from '@xstyled/system'

export const GridContainer = styled(Box)`
  width: ${(props) => (props.position === 'left' ? '80%' : '100%')};
  display: flex;
  flex-direction: column;
  z-index: 0;
  position: relative;
  margin-left: ${(props) => (props.position === 'left' ? '-15px' : '0')};
`

export const Callout = styled(Box)`
  position: absolute;
  width: calc(200% + 1rem);
  height: 100%;
  z-index: 4;
  overflow: hidden;
  pointer-events: none;
  margin: 1rem 0;
`

export const GridRow = styled(Box)``

export const GridCol = styled(Box)`
  padding: 0.5rem 0.5rem;
  cursor: pointer;
`
export const ItemContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  overflow: hidden;
`
export const GridBlockContainer = styled.div`
  position: relative;
  &:before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`
export const OhpeneerBackground = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  img {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    &.next {
      opacity: 0;
      z-index: 2;
    }
  }
  &:before {
    content: '';
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    position: absolute;
    opacity: ${(props) => (props.active ? 0 : 0.6)};
    background: black;
    transition: all 0.5s ease-out;
  }
  &:hover:before {
    opacity: 0;
    transition-delay: 0.2s;
  }
`

export const PanelBackground = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: decoration;
`
export const Content = styled.div`
  position: absolute;
  width: calc(50% - 1rem);
  height: calc(25% - 1rem);
`

export const TextBackground = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  /* background-color: decoration; */
  padding: 0 4rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-direction: column;

  h3 {
    font-family: title;
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: 400;
    line-height: 3.6rem;
    letter-spacing: 0.2px;
  }
  p {
    font-family: primary;
    font-size: 1.2rem;
  }
  ${breakpoints({
    xl: css`
      h3 {
        line-height: 3.6rem;
        font-size: 2.4rem;
      }
      p {
        font-size: 1.6rem;
      }
    `,
  })}
`

export const OhpeneerMobilePageWrap = styled.div`
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 70vh;
`
export const OhpeneerTextColMobile = styled(Box)`
  background-color: decoration;
  position: relative;
  max-height: 20vh;
  .contentHeight {
    padding-bottom: 50%;
  }
  .content {
    position: absolute;
    left: 4rem;
    top: 50%;
    transform: translateY(-50%);
  }
  h3 {
    font-family: title;
    font-size: 2.4rem;
    font-weight: 400;
    text-transform: uppercase;
  }
  p {
    font-family: primary;
    font-size: md;
  }
`
export const OhpeneerMobileRow = styled(Box)``

export const OhpeneerMobileCol = styled(Box)<{ active: boolean }>`
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: content-box;
  padding: 5px;
  .wrapper {
    height: 100%;
    width: 100%;
    position: relative;
    &:before {
      content: '';
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: 3;
      position: absolute;
      opacity: ${(props) => (props.active ? 0 : 0.6)};
      background: black;
      transition: all 0.2s ease-in-out;
    }
  }
  .size {
    padding-bottom: 100%;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center center;
  }
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
`
export const Title = styled.h3`
  margin-bottom: 3.4rem;
  text-transform: uppercase;
  word-break: break-word;
  hyphens: auto;

  .line {
    margin-bottom: -2rem;
    overflow: hidden;
    padding-top: 0.4rem;
  }
`

export const Paragraph = styled.p`
  word-break: break-word;
  hyphens: auto;

  .line {
    overflow: hidden;
  }
`
